require('../../sass/normalize.scss');

require('@material/list/mdc-list.scss');
require('@material/menu/mdc-menu.scss');
require('@material/drawer/mdc-drawer.scss');
require('@material/top-app-bar/mdc-top-app-bar.scss');
require('@material/dialog/mdc-dialog.scss');
require('@material/elevation/mdc-elevation.scss');

require('@os/mdc/dialog.scss');
require('@os/mdc/text-field.scss');
require('@os/mdc/checkbox.scss');
require('@os/mdc/floating-label.scss');
require('@os/mdc/layout-grid.scss');
require('@os/mdc/button.scss');
require('@os/helper/helper.scss');

require('../../sass/header/header.scss');
require('../../sass/header-menu-secondary/header-menu-secondary.scss');
require('../../sass/header-menu-primary/header-menu-primary.scss');
require('../../sass/header-top-app-bar/header-top-app-bar.scss');
require('../../sass/header-drawer/header-drawer.scss');
require('../../sass/header-fab-button/header-fab-button.scss');

define([
	'jquery',
	'@material/ripple',
	'@material/dialog',
	'@material/textfield',
	'@material/drawer',
	'@material/menu',
	'@material/top-app-bar',
	'@os/enroll',
	'@os/form-data',
	'parsleyjs',
	'js-cookie'	
], function ($, mdcRipple, mdcDialog, mdcTextField, mdcDrawer, mdcMenu, mdcTopAppBar, enroll, formData, parsley, jsCookie) {

	var templateLogin = require('../../view/login.hbs');
	var templateForgotPassword = require('../../view/forgot-password.hbs');
	var loginDialog;

	return {
		init: function () {
			
			$(document).on('scroll', function () {
				if ($(window).scrollTop() !== 0) {
					if ($('.mdc-top-app-bar').css('display') != 'none') {
						$('.header__logo').slideUp('fast', function () {
							$('.header-drawer__bar-title-icon').show();
							$('.header-top-app-bar').css('background-color', 'transparent');
							$('.header-top-app-bar').addClass('mdc-elevation--z1');
						});
					} else {
						$('.header__logo').show();
					}
				} else {
					if ($('.mdc-top-app-bar').css('display') != 'none') {
						$('.header-drawer__bar-title-icon').hide();
						$('.header__logo').slideDown('fast');
						$('.header-top-app-bar').css('background-color', 'transparent');
					}
				}
			});

			$(document).on('click', '.header-menu-secondary__menu-item .mdc-button, .header-menu-primary__menu-item .mdc-button, .mdc-top-app-bar__section .language-switcher', function () {
				if ($(this).next('.mdc-menu-anchor').find('.mdc-menu').length) {
					const menu = new mdcMenu.MDCMenu($(this).next('.mdc-menu-anchor').find('.mdc-menu')[0]);

					menu.show();
				}
			});

			$(document).on('click', '.header-drawer__list .header-drawer__list-item', function () {
				if ($(this).next('.header-drawer__sub-list').length) {
					$(this).next('.header-drawer__sub-list').slideToggle('fast');
				}
			});
			$(document).on('click', '.header-drawer__list .header-drawer__sector-list-item', function () {
				if ($(this).next('.header-drawer__sector-sub-list').length) {
					$(this).next('.header-drawer__sector-sub-list').slideToggle('fast');
				}
			});
			$(document).on('click', '.header-drawer__language-item', function (e) {
				e.preventDefault();
				if ($(this).next('.header-drawer__language-sub-list').length) {
					$(this).next('.header-drawer__language-sub-list').slideToggle('fast');
				}
			});
			const topAppBarElement = document.querySelector('.mdc-top-app-bar');
			const topAppBar = new mdcTopAppBar.MDCTopAppBar(topAppBarElement);
			let drawer;
			if (document.querySelector('.mdc-drawer--temporary')) {
				drawer = new mdcDrawer.MDCTemporaryDrawer(document.querySelector('.mdc-drawer--temporary'));
			}

			$(document).on('click', function () {
				$('.mdc-top-app-bar').show();
			});

			$(document).on('click', '.top-bar-menu', function (e) {
				drawer.open = true;
				$('.mdc-top-app-bar').hide();
				e.preventDefault();
			});

			$('.header .mdc-button, .header .mdc-list-item').each(function (key, object) {
				mdcRipple.MDCRipple.attachTo(object);
			});

			if ($('.header .mdc-icon-button').length) {
				const iconButtonRipple = new mdcRipple.MDCRipple(document.querySelector('.header .mdc-icon-button'));
				iconButtonRipple.unbounded = true;
			}

			// Login

			/*if (document.querySelector('.mdc-icon-button')) {
				const iconButtonRipple = new mdcRipple.MDCRipple(document.querySelector('.mdc-icon-button'));
				iconButtonRipple.unbounded = true;
			}*/

			var path = window.location.pathname;
			path = path.replace(/\/$/, "");
			path = decodeURIComponent(path);
			path = path.split('/');

			$(".header-menu-primary__menu-item > a").each(function () {
				var href = $(this).attr('href');
				if (href) {
					strippedHref = href.substr(href.lastIndexOf('/') + 1)
					if (path.length && path[path.length - 1] === strippedHref) {
						$(this).addClass('active');
					}
				}
			});
			/**
			 * Login
			 */
			this.login();

			/**
			 * Fab Button
			 */
			if (document.querySelector('.mdc-fab__container')) {
				this.fabActions();
			}

			/**
			 * Language
			 */
			this.setLanguage();
		},
		setLanguage: function () {
			currentLang = jsCookie.get('locale').toLowerCase();
			switch (currentLang) {
				case 'fr-fr':
					$('.current-lang').html("Français")
					break;
				case 'en-ca':
					$('.current-lang').html("English");
					break;
				case 'nl-be':
					$('.current-lang').html("Nederlands");
					break;
				case 'it-it':
					$('.current-lang').html("Italiano");
					break;
				default:
					$('.current-lang').html("Français")
					break;
			}
			$('.lang-option').on('click', function (event) {
				event.preventDefault();
				var newLang = $(this).data('lang');
				var currentUrl = window.location.pathname;
				var b = currentUrl.split('/');
				var newUrl = window.location.href;

				var newNewUrl = newUrl.replace(b[1], newLang);
				window.location.href = newNewUrl;

			})
		},
		login: function () {
			$.ajax({
				type: 'POST',
				url: '/api/login',
				dataType: 'json',
				context: this

			}).done(function (data) {
				$("#modalLogin").empty().append(templateLogin(data));
				$("#modalForgotPassword").empty().append(templateForgotPassword());
				$('.forgot-password-error').hide();
				$('.forgot-password-success').hide();

				if (document.getElementById('dialog_login')) {
					loginDialog = new mdcDialog.MDCDialog(document.getElementById('dialog_login'));
					$("#modalLogin").find('.mdc-text-field').each(function (index, value) {
						mdcTextField.MDCTextField.attachTo($(this)[0]);
					});
				}

				$("#modalForgotPassword").find('.mdc-text-field').each(function (index, value) {
					mdcTextField.MDCTextField.attachTo($(this)[0]);
				});

				$(document).on('click', '.modal-login', function () {
					$(".modal-login-error").hide();
					loginDialog.show();
				});

				$(document).on('click', '.modal-forgot-password', function (event) {
					event.preventDefault();
					loginDialog.close();
					mdcDialog.MDCDialog.attachTo($('#dialog_forgot_password')[0]).show();
				});

				const urlParams = new URLSearchParams(window.location.search);
				if(urlParams.has('autherror')){
					$(".modal-login-error").show();
					loginDialog.show();
				}

				$(document).on('click', 'button[data-action="send-forgot-password-mail"]', function () {
					if ($('form[name="forgot-password-form"]').parsley().validate()) {
						$.ajax({
							type: 'POST',
							url: '/api/password/request',
							data: formData(this),
							dataType: 'json',
							context: this
						}).done(function (data) {
							if (data.success) {
								$('.forgot-password-error').hide();
								$('.forgot-password-success').show();
							}
							if (data.error) {
								$('.forgot-password-success').hide();
								$('.forgot-password-error').show();
							}
						})
					}
				})
				enroll.init();
			});
		},

		fabActions: function () {
			const trigger = document.querySelector('.primary');
			const body = document.querySelector('body');
			const container = document.querySelector('.mdc-fab__container');
			const backdrop = container.querySelector('.mdc-fab__container-backdrop');

			trigger.addEventListener('click', function (e) {
				e.preventDefault();


				body.classList.toggle('mdc-header-fab-scroll-lock');
				container.classList.toggle('active');

				backdrop.addEventListener('click', function () {
					container.classList.remove('active');

					backdrop.removeEventListener('click', arguments.callee);
				});
			});
		}
	};
});