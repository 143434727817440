require('../../sass/normalize.scss');

require('@os/mdc/button.scss');
require('@os/helper/helper.scss');

require('../../sass/message-bar/message-bar.scss');


define([
	'jquery', '@material/ripple', '@material/dialog',
	'@material/textfield', '@material/drawer', '@material/menu',
	'@material/top-app-bar', '@material/checkbox', '@material/radio', '@os/enroll', 'js-cookie'], function($, mdcRipple, mdcDialog, mdcTextField, mdcDrawer, mdcMenu, mdcTopAppBar, mdcCheckbox, mdcRadio, enroll, jsCookie) {

	var templateMessage = require('../../view/message.hbs');

	return {

		init: function() {

			var adjustContent = function() {
				$('.message-bar--offset-margin-top').css('margin-top', $('.message-bar__container').innerHeight() + 'px');
			}



			if (jsCookie.get('messageDismissed') != 1) {

				$.ajax({
					type: "POST",
					url: "/api/message-bar/job/fair",
					dataType: "json"
				}).done(function(data) {
					if (data.job_fair_event_id != null) {
						var expiryDate = new Date(data.datetime);
						
						$('body').prepend(templateMessage(data));
						$('#message_bar').slideDown({
							step: adjustContent,
							complete: function() {
								adjustContent();
								$(document).on('click', '#close_button', function() {
									$('#close_button').hide();
									$(window).off('resize', adjustContent);
									$('#message_bar').slideUp({
										step: adjustContent
									});
									
									jsCookie.set('messageDismissed', 1, { expires: expiryDate });
									//cookie expire after the date of the event.
									//cookie to make sure the bar is gone for good.
								});
							}
						});

						$(window).on('resize', adjustContent);
					}
				}); 

			}
			
		}

	};

});

