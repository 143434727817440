var Handlebars = require("/srv/public/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<aside id=\"modalEnroll\" class=\"mdc-dialog os-dialog dialog-enroll static\" role=\"alertdialog\"\n	aria-labelledby=\"my-mdc-dialog-label\" aria-describedby=\"dialog_enroll_description\" data-dialog-static=\"true\">\n	<div class=\"mdc-dialog__surface os-dialog__surface--small\">\n		<header class=\"mdc-dialog__header\">\n			<h2 id=\"mdc-dialog-with-list-label\" class=\"mdc-dialog__header__title\">\n				"
    + ((stack1 = __default(require("/srv/public/module/handlebars-helpers/i18n_t.js")).call(alias1,"enroll:title",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":31}}})) != null ? stack1 : "")
    + "\n			</h2>\n		</header>\n		<section id=\"dialog_enroll_description\" class=\"mdc-dialog__body mdc-dialog__body--scrollable\">\n			\n		</section>\n		<footer class=\"mdc-dialog__footer\">\n			<form action=\"/ajax/enroll.php\" method=\"post\" modal=\"true\">\n				<button type=\"button\"\n					class=\"mdc-button mdc-dialog__footer__button mdc-dialog__footer__button--cancel mdc-button--outlined os-button--primary-opaque-outlined\">"
    + ((stack1 = __default(require("/srv/public/module/handlebars-helpers/i18n_t.js")).call(alias1,"enroll:button.close",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":15,"column":142},"end":{"line":15,"column":176}}})) != null ? stack1 : "")
    + "</button>\n				<button type=\"button\"\n					class=\"mdc-button mdc-dialog__footer__button mdc-button--raised os-button--primary\"\n					data-action=\"enroll-next-step\" data-step=\"1\">"
    + ((stack1 = __default(require("/srv/public/module/handlebars-helpers/i18n_t.js")).call(alias1,"enroll:button.enroll",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":18,"column":50},"end":{"line":18,"column":85}}})) != null ? stack1 : "")
    + "</button>\n			</form>\n		</footer>\n	</div>\n	<div class=\"mdc-dialog__backdrop\"></div>\n</aside>";
},"useData":true});