define(['handlebars', 'js-cookie'], function (handlebars, jsCookie) {

	return function (locale, options) {

		if (jsCookie.get('locale').toLowerCase() == locale.toLowerCase()) {
			return options.fn(this);
		}
	}

});
