define(['handlebars', 'js-cookie'], function(handlebars, jsCookie) {

	return function(options) {

		if (!jsCookie.get('pro')) {
			return options.fn(this);
		}
	}

});
