require('@material/dialog/mdc-dialog.scss');
require('@material/form-field/mdc-form-field.scss');
require('@material/checkbox/mdc-checkbox.scss');

require('@os/mdc/button.scss');
require('@os/mdc/text-field.scss');
require('@os/mdc/floating-label.scss');

define(['jquery', '@material/textfield', '@material/ripple', '@os/form-data', 'i18next'], function($, mdcTextField, mdcRipple, formData, i18next) {

	return {
		init: function() {
			$(document).on('keyup', 'input[name="newsletter_email"]', function(e) {
				$(this).css({'color': '#004f9f'});
			});

			$(document).on('click', 'button[data-action="subscribe-newsletter"]', function(e) {
				e.preventDefault();
				e.stopPropagation();

				$('.bottom-subscribe-newsletter__error-message').hide();
				$('input[name="newsletter_email"]').css({'color': '#004f9f'});

				if ($.trim($('input[name="newsletter_email"]').val()) != '') {
					var regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
					var test = regex.test(String($('input[name="newsletter_email"]').val()).toLowerCase());
					if (test) {
						$.ajax({
							type: 'POST',
							url: '/api/subscribe/newsletter',
							dataType: 'json',
							data: { email:$('input[name="newsletter_email"]').val() }
						}).done(function( data ) {
							if (data.success) {
								//Show success mesaage
								$('.bottom-subscribe-newsletter__control-section').hide();
								$('.bottom-subscribe-newsletter__success-newsletter-section').show();
							} else {
								if (data.reason.invalid) {
									$('.bottom-subscribe-newsletter__error-message span').html(i18next.t('bottom-subscribe-newsletter:error.invalid'));
								} else if (data.reason.exist) {
									$('.bottom-subscribe-newsletter__error-message span').html(i18next.t('bottom-subscribe-newsletter:error.exist'));
								} else if (data.reason.system) {
									$('.bottom-subscribe-newsletter__error-message span').html(i18next.t('bottom-subscribe-newsletter:error.system'));
								}
								$('input[name="newsletter_email"]').css({'color': '#FF0000'});
								$('.bottom-subscribe-newsletter__error-message').show();
							}
						});
					} else {
						$('input[name="newsletter_email"]').css({'color': '#FF0000'});
						$('.bottom-subscribe-newsletter__error-message span').html(i18next.t('bottom-subscribe-newsletter:error.invalid'));
						$('.bottom-subscribe-newsletter__error-message').show();
					}
				} else {
					$('input[name="newsletter_email"]').css({'color': '#FF0000'});
					$('.bottom-subscribe-newsletter__error-message span').html(i18next.t('bottom-subscribe-newsletter:error.invalid'));
					$('.bottom-subscribe-newsletter__error-message').show();
				}
			});
		}
	};
});