
define(['@babel/polyfill', 'i18next', 'js-cookie'], function(babelPolyfill, i18next, jsCookie) {

	var locale=jsCookie.get('locale');

	return new Promise(function(resolve) {
		
		import('../../locale/'+locale+'/index.js').then(function(resources){
			
			i18next.init(
			{
				lng: locale,
				initImmediate: false,
				fallbackLng: 'fr-FR',
				resources: {
					"it-IT": resources["it-IT"],
					"fr-FR": resources["fr-FR"],
					"en-CA": resources["en-CA"],
					"nl-BE": resources["nl-BE"]
					},
				load: 'all'
			});

			resolve(true);

		});
	});

});
