
define(['jquery'], function($) {

	return {
		parseArguments: function(prefix) {
			var url = location.href;

			const regex = /[/][a-z][a-z][-][a-z][a-z][/]/gm;
			
			let m;
			var match = regex.exec(url);

			var path = url.slice(match.index+1, url.length);

			var index = path.indexOf(prefix)+prefix.length;
			var params = path.slice(index);
			
			return params.split('/');
			
			
		}
	}
});

