var Handlebars = require("/srv/public/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <div class=\"facebook-feed__post-item\">\r\n        <div class=\"facebook-feed__post-header\">\r\n            <img src=\""
    + alias4(((helper = (helper = helpers.page_icon || (depth0 != null ? depth0.page_icon : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"page_icon","hash":{},"data":data,"loc":{"start":{"line":4,"column":22},"end":{"line":4,"column":35}}}) : helper)))
    + "\" />\r\n            <a href=\"https://www.facebook.com/opinionsystem\" target=\"_blank\"><span class=\"facebook-feed__page-title\">Opinion System</span></a>\r\n            <span class=\"facebook-feed__created-at\">"
    + ((stack1 = ((helper = (helper = helpers.created_time || (depth0 != null ? depth0.created_time : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"created_time","hash":{},"data":data,"loc":{"start":{"line":6,"column":52},"end":{"line":6,"column":70}}}) : helper))) != null ? stack1 : "")
    + "</span>\r\n        </div>\r\n        <div class=\"facebook-feed__post-content\"><p>"
    + ((stack1 = ((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data,"loc":{"start":{"line":8,"column":52},"end":{"line":8,"column":65}}}) : helper))) != null ? stack1 : "")
    + "</p><img src=\""
    + alias4(((helper = (helper = helpers.post_picture || (depth0 != null ? depth0.post_picture : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"post_picture","hash":{},"data":data,"loc":{"start":{"line":8,"column":79},"end":{"line":8,"column":95}}}) : helper)))
    + "\"/></div>\r\n    </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.facebook_feed : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":10,"column":9}}})) != null ? stack1 : "");
},"useData":true});