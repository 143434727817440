var Handlebars = require("/srv/public/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<aside id=\"dialog_forgot_password\" class=\"mdc-dialog os-dialog\" role=\"alertdialog\" aria-labelledby=\"my-mdc-dialog-label\" aria-describedby=\"dialog_forgot_password_description\" data-dialog-static=\"true\">\r\n	<div class=\"mdc-dialog__surface os-dialog__surface--small\">\r\n		<header class=\"mdc-dialog__header\">\r\n			<h2 id=\"mdc-dialog-with-list-label\" class=\"mdc-dialog__header__title\">\r\n				"
    + ((stack1 = __default(require("/srv/public/module/handlebars-helpers/i18n_t.js")).call(alias1,"forgot-password:title",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":40}}})) != null ? stack1 : "")
    + "\r\n			</h2>\r\n		</header>\r\n		<form class=\"form-horizontal\" name=\"forgot-password-form\" method=\"post\" modal=\"true\">\r\n			<section id=\"dialog_forgot_password_description\" class=\"mdc-dialog__body\">\r\n				<div class=\"dialog-body-title\">"
    + ((stack1 = __default(require("/srv/public/module/handlebars-helpers/i18n_t.js")).call(alias1,"forgot-password:body_title",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":10,"column":35},"end":{"line":10,"column":76}}})) != null ? stack1 : "")
    + ":</div>\r\n				<div class=\"mdc-layout-grid no-padding\">\r\n					<div class=\"mdc-layout-grid__inner\">\r\n						<div class=\"mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop\">\r\n							<div class=\"form-group\" id=\"forgot-password-username-group\">\r\n								<div class=\"mdc-text-field os-text-field mdc-text-field--fullwidth mdc-text-field--box\">\r\n									<input type=\"text\" id=\"forgot_password_user_name\" name=\"user_name\" class=\"mdc-text-field__input\" data-parsley-required=\"true\" data-parsley-errors-container=\"#forgot-password-username-group\" data-parsley-required-message=\""
    + ((stack1 = __default(require("/srv/public/module/handlebars-helpers/i18n_t.js")).call(alias1,"forgot-password:username_validation",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":16,"column":230},"end":{"line":16,"column":280}}})) != null ? stack1 : "")
    + "\">\r\n									<label for=\"forgot_password_user_name\" class=\"mdc-floating-label os-floating-label mdc-floating-label--shake\">"
    + ((stack1 = __default(require("/srv/public/module/handlebars-helpers/i18n_t.js")).call(alias1,"forgot-password:username",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":17,"column":119},"end":{"line":17,"column":158}}})) != null ? stack1 : "")
    + " *</label>\r\n									<div class=\"mdc-line-ripple\"></div>\r\n								</div>\r\n							</div>\r\n						</div>\r\n					</div>\r\n				</div>\r\n				<div class=\"mdc-layout-grid no-padding\">\r\n					<div class=\"mdc-layout-grid__inner\">\r\n						<div class=\"mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop\">\r\n							<div class=\"form-group\" id=\"forgot-password-email-group\">\r\n								<div class=\"mdc-text-field os-text-field mdc-text-field--fullwidth mdc-text-field--box\">\r\n									<input type=\"text\" id=\"forgot_password_email\" name=\"email\" class=\"mdc-text-field__input\" data-parsley-required=\"true\" data-parsley-errors-container=\"#forgot-password-email-group\" data-parsley-type=\"email\" data-parsley-error-message=\""
    + ((stack1 = __default(require("/srv/public/module/handlebars-helpers/i18n_t.js")).call(alias1,"forgot-password:email_validation",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":29,"column":242},"end":{"line":29,"column":289}}})) != null ? stack1 : "")
    + "\">\r\n									<label for=\"forgot_password_email\" class=\"mdc-floating-label os-floating-label\">"
    + ((stack1 = __default(require("/srv/public/module/handlebars-helpers/i18n_t.js")).call(alias1,"forgot-password:email",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":30,"column":89},"end":{"line":30,"column":125}}})) != null ? stack1 : "")
    + " *</label>\r\n									<div class=\"mdc-line-ripple\"></div>\r\n								</div>\r\n							</div>\r\n						</div>\r\n					</div>\r\n				</div>\r\n				<p class=\"forgot-password-success\">"
    + ((stack1 = __default(require("/srv/public/module/handlebars-helpers/i18n_t.js")).call(alias1,"forgot-password:success",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":37,"column":39},"end":{"line":37,"column":77}}})) != null ? stack1 : "")
    + "</p>\r\n				<p class=\"forgot-password-error\">"
    + ((stack1 = __default(require("/srv/public/module/handlebars-helpers/i18n_t.js")).call(alias1,"forgot-password:error",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":38,"column":37},"end":{"line":38,"column":73}}})) != null ? stack1 : "")
    + "</p>\r\n			</section>\r\n			<footer class=\"mdc-dialog__footer\">\r\n					<button type=\"button\" class=\"mdc-button mdc-dialog__footer__button mdc-dialog__footer__button--cancel mdc-button--outlined os-button--primary-outlined\">"
    + ((stack1 = __default(require("/srv/public/module/handlebars-helpers/i18n_t.js")).call(alias1,"forgot-password:button.close",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":41,"column":157},"end":{"line":41,"column":200}}})) != null ? stack1 : "")
    + "</button>\r\n					<button type=\"button\" class=\"mdc-button mdc-dialog__footer__button mdc-button--raised os-button--primary\" data-action=\"send-forgot-password-mail\" data-step=\"1\">"
    + ((stack1 = __default(require("/srv/public/module/handlebars-helpers/i18n_t.js")).call(alias1,"forgot-password:button.connect",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":42,"column":165},"end":{"line":42,"column":210}}})) != null ? stack1 : "")
    + "</button>\r\n			</footer>\r\n		</form>\r\n	</div>\r\n	<div class=\"mdc-dialog__backdrop\"></div>\r\n</aside>";
},"useData":true});