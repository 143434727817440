require('@material/dialog/mdc-dialog.scss');
require('@material/form-field/mdc-form-field.scss');
require('@material/checkbox/mdc-checkbox.scss');

require('@os/mdc/dialog.scss');
require('@os/mdc/text-field.scss');
require('@os/mdc/checkbox.scss');
require('@os/mdc/floating-label.scss');

require('../../sass/plain-overlay/plain-overlay.scss');
require('../../sass/enroll/enroll.scss');

define(['jquery', '@material/dialog', '@material/textfield',
	'@material/checkbox', '@material/radio', '@os/form-data',
	'parsleyjs', 'js-cookie'
], function ($, mdcDialog, mdcTextField, mdcCheckbox,
	mdcRadio, formData, parsley, jsCookie) {

	jQuery = $;
	require('jquery-plainoverlay');
	var templateEnroll = require('../../view/enroll.hbs');

	return {
		init: function () {
			$(document).on('click', '[data-action="enroll"]', function (event) {
				event.preventDefault();
				event.stopPropagation();

				showLoadingOverlay();

				var promises = [];
				promises.push(new Promise(function (resolve, reject) {
					$.ajax({
						type: 'POST',
						url: '/api/enroll/load',
						data: {
							locale: jsCookie.get('locale')
						},
						dataType: 'json'
					}).done(function (data) {
						resolve(data);
					}).fail(function (xhr, error) {
						reject(error);
					});
				}));

				Promise.all(promises).then(function (result) {

					var templateData = {
						locale: jsCookie.get('locale').toLowerCase()
					};

					if ($('#modalEnroll').length > 0) {
						$('#modalEnroll').remove();
					}
					$("body").append(templateEnroll(templateData));

					dialog = new mdcDialog.MDCDialog(document.querySelector('#modalEnroll'));
					hbspt.forms.create({
						region: "eu1",
						portalId: result[0].hubspot_form.portal_id,
						formId: result[0].hubspot_form.form_id,
						target: "#modalEnroll #dialog_enroll_description",
						onFormReady: function ($form) {
							document.querySelector('#modalEnroll iframe').contentDocument.forms[0].querySelector('input[type="submit"]').style.display = 'none';
							dialog.show();
							hideLoadingOverlay();
						},
						onFormSubmitted: function ($form) {
							document.querySelectorAll('#modalEnroll iframe')[0].contentDocument.querySelector('.submitted-message').style.paddingBottom = '50px';
							document.querySelectorAll('#modalEnroll')[0].querySelector('button[data-action="enroll-next-step"]').style.display = 'none';
						}
					});
				}, function (reason) {
					// error occurred
				});
			});

			$(document).on('click', 'button[data-action="enroll-next-step"]', function (event) {
				event.preventDefault();
				event.stopPropagation();

				switch ($(this).data("step")) {
					case 1:
						document.querySelector('#modalEnroll iframe').contentDocument.forms[0].querySelector('input[type="submit"]').click();
						break;
				}
			});

			if (window.location.hash) {
				var hash = window.location.hash.substring(1);
				if (hash == "demo")
					$(".invoke-demo").click();
			}

			function showLoadingOverlay(target) {
				if (typeof target === 'undefined') {
					target = 'body';
				}
				$(target).plainOverlay('show', {
					progress: function () {
						return $('<div class="plain-overlay__icon"></div>');
					}
				});
			}

			function hideLoadingOverlay(target) {
				if (typeof target === 'undefined') {
					target = 'body';
				}
				$(target).plainOverlay('hide');
			}
		}
	};
});