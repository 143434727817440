
define(['jquery', 'i18next', 'js-cookie'], function($, i18next, jsCookie) {
	return {
		loaded: false,
		promise: null,
		siteKey: null,
		recaptcha: function() {
			if (typeof grecaptcha == 'object' && typeof grecaptcha.render == 'function') {
				this.loaded = true;
			}
		},
		init: function() {
			var context = this;
			this.promise = new Promise(function(resolve, reject) {
				global.recaptcha = function() {
					if (typeof grecaptcha == 'object' && typeof grecaptcha.render == 'function') {
						context.loaded = true;
						resolve(context);
					}
				};
				$.ajax({
					type: 'GET',
					url: '/api/google/recaptcha',
					dataType: 'json'
				}).done(function( data ) {
					if (!$('head > script[id="googlRecaptcha"]').length) {
						var scriptTag = document.createElement('script');
						scriptTag.src='https://www.google.com/recaptcha/api.js?onload=recaptcha&render=explicit&hl=' + jsCookie.get('locale');
						scriptTag.id = 'googlRecaptcha';
						scriptTag.type = 'text/javascript';
						scriptTag.defer = true;
						$("head").append(scriptTag);
					}
					context.siteKey = data.recaptcha.public_key;
				});
			});
		},
		_render: function(target) {
			grecaptcha.render($(target + ' .google_recaptcha_block')[0], {
				'sitekey' : this.siteKey,
				'theme' : 'light',
				'callback': function () {
					$(target + ' [name="recaptcha_validation"]').val('1');
					$(target + ' [name="recaptcha_validation"]').parsley().validate();
				},
				'expired-callback': function () {
					grecaptcha.reset();
					$(target + ' [name="recaptcha_validation"]').val('');
				}
			});
		},
		render: function(target) {
			if (this.loaded) {
				this._render(target);
			} else {
				if (this.promise) {
					this.promise.then(function(context) {
						context._render(target);
					});
				} else {
					this.init();
					this.promise.then(function(context) {
						context._render(target);
					});
				}
			}
		}
	};
});