var Handlebars = require("/srv/public/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.widget_no : depth0), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    return "phone";
},"7":function(container,depth0,helpers,partials,data) {
    return " data-parsley-required ";
},"9":function(container,depth0,helpers,partials,data) {
    return "data-parsley-group=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.parsley_group : depth0), depth0))
    + "\"";
},"11":function(container,depth0,helpers,partials,data) {
    return "*";
},"13":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.lang : depth0), depth0));
},"15":function(container,depth0,helpers,partials,data) {
    return "fre";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"widget-phone-mdc\" id=\"phone_widget_error_group"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.widget_no : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":58},"end":{"line":1,"column":95}}})) != null ? stack1 : "")
    + "\">\r\n    <div class=\"widget-phone-wrapper\">\r\n        <button type=\"button\" class=\"mdc-button mdc-button--unelevated os-button--primary country-icon-button\" data-action=\"show-country-menu\">\r\n            <div class=\"mdc-button__icon country-icon\"></div><span class=\"country-regional-code\"></span> <i class=\"mdc-button__icon fa fa-caret-down\"></i>\r\n        </button>\r\n        <div class=\"mdc-menu\" tabindex=\"-1\">\r\n            <ul class=\"mdc-menu__items mdc-list\" role=\"menu\" aria-hidden=\"true\">\r\n            </ul>\r\n        </div>\r\n        <div class=\"mdc-text-field mdc-text-field--box mdc-text-field--with-trailing-icon os-text-field phone-widget-input\">\r\n            <input type=\"text\" id=\""
    + alias3(alias2((depth0 != null ? depth0.id : depth0), depth0))
    + "\" name=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":11,"column":49},"end":{"line":11,"column":89}}})) != null ? stack1 : "")
    + "\" class=\"mdc-text-field__input\" data-parsley-trigger=\"change\" data-parsley-remote data-parsley-remote-validator=\"mycustom\" data-parsley-errors-container=\"#phone_widget_error_group"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.widget_no : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":268},"end":{"line":11,"column":305}}})) != null ? stack1 : "")
    + "\" data-parsley-error-message=\""
    + ((stack1 = __default(require("/srv/public/module/handlebars-helpers/i18n_t.js")).call(alias1,"phone-widget:valid_phone",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":11,"column":335},"end":{"line":11,"column":374}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.required : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":376},"end":{"line":11,"column":422}}})) != null ? stack1 : "")
    + " value=\""
    + ((stack1 = alias2((depth0 != null ? depth0.mobile : depth0), depth0)) != null ? stack1 : "")
    + "\" data-type=\""
    + alias3(alias2((depth0 != null ? depth0.id : depth0), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.parsley_group : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":463},"end":{"line":11,"column":529}}})) != null ? stack1 : "")
    + ">\r\n            <label class=\"mdc-floating-label\">"
    + alias3(alias2((depth0 != null ? depth0.label : depth0), depth0))
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.required : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":56},"end":{"line":12,"column":80}}})) != null ? stack1 : "")
    + "</label><i class=\"material-icons mdc-text-field__icon\" tabindex=\"0\" role=\"button\"><svg markup-inline   aria-hidden=\"true\" data-prefix=\"fas\" data-icon=\"info-circle\" class=\"svg-inline--fa fa-info-circle fa-w-16\" role=\"img\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 512 512\"><path fill=\"currentColor\" d=\"M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z\"></path></svg></svg></i>\r\n            <div class=\"mdc-line-ripple\"></div>\r\n        </div>\r\n        <div class=\"clearfix\"></div>\r\n        <div class=\"widget-phone-mdc-tooltip\" id=\"phone_tooltip\"></div>\r\n        <input type=\"hidden\" class=\"country-code\" name=\"phone_country"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.widget_no : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":69},"end":{"line":17,"column":106}}})) != null ? stack1 : "")
    + "\" value=\""
    + alias3(alias2((depth0 != null ? depth0.default_country : depth0), depth0))
    + "\" />\r\n        <input type=\"hidden\" class=\"phone_widget_language\" name=\"phone_widget_language"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.widget_no : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":86},"end":{"line":18,"column":123}}})) != null ? stack1 : "")
    + "\" value=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.lang : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":18,"column":132},"end":{"line":18,"column":170}}})) != null ? stack1 : "")
    + "\" />\r\n    </div>\r\n</div>";
},"useData":true});