
define(['jquery'], function($) {

	return function(target, data) {

		var result = new Array();

		if (data) {
			result[result.length] = $.param(data);
		}

		if ($(target).parents('form[modal="true"]').length) {
			$(target).parents('.modal').find('form[modal="in"]').each(function(index) {
				if ($(this).serialize()) {
					result[result.length] = $(this).serialize();
				}
			});
		}

		if ($(target).parents('form').serialize()) {
			result[result.length] = $(target).parents('form').serialize();
		}

		return result.join('&');
	}

});
